import styled from "styled-components";

export const H1 = styled.h1`
    text-align: start;
    margin-bottom: 30px;
`;

export const H6 = styled.h6`
    text-align: ${(props) => props.center ? "center" : "start"};
    margin-bottom: 30px;
`;

export const P = styled.p`
    text-align: start;
`;

export const LiteText = styled.p`
    text-align: start;
    font-size: 15px;
    ${((props)=> props.center ? `text-align: center;` : ``)}
`;

export const FinePrint = styled.p`
    text-align: start;
    font-size: 10px;
    ${((props)=> props.center ? `text-align: center;` : ``)}
`;