import React from "react";
import logo from "./assets/images/logoIcon.png";

import everyBusiness from "./assets/images/everyBusiness.png";
import b2 from "./assets/images/b2.png";
import b3 from "./assets/images/b3.png";

import facebook from "./assets/images/facebook.png";
import email from "./assets/images/note.png";

import "./App.css";
import MagicContainer from "./atomic/molecules/MagicContainer";
import { Container, Row, Col } from "reactstrap";
import { Image } from "./atomic/atoms/Image";
import { H1, H6, P, FinePrint, LiteText } from "./atomic/atoms/Text";

function App() {
  return (
    <div className="App">
      <MagicContainer minHeight="100vh" move={true}>
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Triacom</h1>
        <p>Uma solução, infinitas oportunidades.</p>
      </MagicContainer>

      <section className="App-section">
        <Container>
          <Row>
            <Col sm="12" md="6">
              <Image src={everyBusiness} />
            </Col>
            <Col sm="12" md="5">
              <H1>
                Saia da era do papel e organize seu negócio com mais eficiência
              </H1>
              <P>
                Obtenha maior controle de suas vendas, distribua comissões com
                mais velocidade e segurança.
              </P>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="App-section">
        <Container>
          <Row>
            <Col sm="12" md="6">
              <H1>Sem limites</H1>
              <P>
                Independente de onde esteja o seu negócio, chegaremos até você
                com a versatilidade de nossa solução.
              </P>
            </Col>
            <Col sm="12" md="6">
              <Image src={b2} />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="App-section">
        <Container>
          <Row>
            <Col sm="12" md="6">
              <Image src={b3} />
            </Col>
            <Col sm="12" md="5">
              <H1>Facite seu negócio</H1>
              <P>
                Monitore seu faturamento e economize tempo com poucos toques
              </P>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="footer">
        <Container>
          <Row>
            <Col className="contacts" xs="12" md="2">
              <H6 center>Contatos</H6>
              <Row>
                <Col xs={{ size: 3, offset: 2 }} md={{ size: 5, offset: 1 }}>
                  <a
                    rel="nofollow"
                    target="_blank"
                    href="mailto:comunicacao@triacom.com.br"
                  >
                    <Image src={email} />
                  </a>
                </Col>
                <Col xs={{ size: 3, offset: 2 }} md={{ size: 5, offset: 0 }}>
                  <LiteText>
                    <a href="https://www.facebook.com/Triacom-Tecnologia-174165495966937">
                      <Image src={facebook} />
                    </a>
                  </LiteText>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <FinePrint center>
                © 2020 Triacom - TRIACOM TECNOLOGIA., CNPJ 36.372.636/0001-01,
                Av. República Argentina 1336 - Sala 905 - Curitiba, Paraná,
                Brasil. comunicacao@triacom.com.br.
              </FinePrint>
            </Col>
            <Col xs="12" md="6">
              <FinePrint center>Colaboradores</FinePrint>
              <FinePrint center>
                Vcalazas - VCALAZAS INFORMATICA. CNPJ 34.700.762/0001-12, RUA
                PASTOR ANTONIO POLITO 1886 - APT 11;BLOCO 08 - Curitiba, Paraná,
                Brasil.
              </FinePrint>
              <FinePrint center>
              M. O. S. PROGRAMACAO - M. O. S. PROGRAMACAO E TREINAMENTO EM INFORMATICA EIRELI. CNPJ 34.910.438/0001-29, RUA
                PASTOR ANTONIO POLITO 1886 - APT 11;BLOCO 08 - Curitiba, Paraná,
                Brasil.
              </FinePrint>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default App;
