import React, { useState } from 'react';
import styled from "styled-components";
import UseInterval from '../utils/UseInterval';

export const AnimatedContent1 = styled.div`
    min-height: ${ props => props.minHeight !== undefined ? props.minHeight : `100%`};
    background: ${ props => props.background !== undefined ? props.background : `#1E1E2F`};
    transition-duration: 10000ms;
    ${ props => props.textCenter === true ? `text-align: center;` : ``}
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
`


const MagicContainer = (props) => {

    const a = "polygon(0 0, 100% 0%, 100% 100%, 0 77%)"
    const b = "polygon(0 0, 100% 0%, 100% 83%,  0% 100%)"

    const [mclipPath, setMclipPath] = useState(a)
    if (props.move === true)
        UseInterval(() => setMclipPath(mclipPath === a ? b : a), 10000)
    return (
        <>
            <AnimatedContent1
                {...props}
                style={{ clipPath: mclipPath }}
            >
                {props.children}
            </AnimatedContent1>
        </>
    )
}

export default MagicContainer;